.Progress {
  &-bar {
    height: 5px;
    background-color: #ffffff;
    border-radius: 0 5px 5px 0;
    transition: width 0.2s ease;
  }
  &-circle {
    padding: 12px 15px 10px;
    &-inner {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border: 5px solid #ffffff;
      border-radius: 100%;
      span {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
      }
    }
  }
}
