.Girl {
  position: relative;
  margin: 1.5vh auto 0;
  width: 95%;
  max-width: 400px;
  z-index: 1;
  &-image {
    width: 100%;
    border-radius: 30px;
  }
}
