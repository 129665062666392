.Cover {
  position: absolute;
  z-index: 3000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(179.66deg, #ff6d6d 0.3%, #ffd02b 99.72%);
  transform: translate(0, 0);
  transition: all 0.4s ease;
  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0.2;
    background: transparent url("../image/cover.svg") no-repeat top 50% left 50%;
    background-size: cover;
    transform: rotate(0);
    transition: all 0.4s ease;
  }
  &.is-hidden {
    transform: translate(0, -120%);
  }
  &.is-boys {
    background: linear-gradient(179.66deg, #2e36ff 0.3%, #2ce7ba 99.72%);
    &::before {
      transform: rotate(180deg);
    }
    .Cover-yes {
      background: #173afb;
    }
  }
  &-inner {
    color: #ffffff;
  }
  &-title {
    margin: 30px auto 0;
    font-weight: bold;
    font-size: 34px;
    color: #ffffff;
  }
  &-subTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 40px;
    font-weight: bold;
    font-size: 18px;
    &:before,
    &:after {
      display: block;
      margin: 0 10px;
      content: "";
      width: 30px;
      height: 1px;
      background: white;
    }
  }
  &-desc {
    font-weight: bold;
    font-size: 18px;
  }
  &-selector {
    position: relative;
    width: 80%;
    margin: 0 auto;
    max-width: 300px;
    display: flex;
    justify-content: space-around;
    z-index: 1;
  }
  &-yes {
    padding: 10px 30px;
    border-radius: 23px;
    background: #e91e63;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.4s ease;
  }
  &-no {
    padding: 10px 30px;
    border-radius: 23px;
    border: solid 1px#ffffff;
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }
}
