.Result {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 200%);
  z-index: 300;
  background: #54a6f2;
  transition: all 0.4s ease;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  &.is-display {
    transform: translate(-50%, -50%);
  }
  &.is-correct {
    background: #ff6d6d;
    .Result-button {
      background: #cb0000;
    }
  }
  &-image {
    margin-top: 40px;
    width: 76px;
    height: 76px;
  }
  &-text {
    padding: 11px 0 19px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    &-en {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
    }
    &-ja {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
  &-button {
    margin: 0 auto 22px;
    padding: 6px;
    width: 180px;
    background: #1e64a5;
    border-radius: 21px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    cursor: pointer;
  }
}
