.Score {
  height: 95%;
  width: 95%;
  position: absolute;
  top: 2.5%;
  left: 50%;
  transform: translate(-50%, 120%);
  transition: all 0.4s ease;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  background: #8fcc48;
  border-radius: 12px;
  z-index: 1000;
  &.is-open-score {
    transform: translate(-50%, 0%);
  }
  &.is-open-share {
    filter: blur(3px);
  }
  &-inner {
    padding: 10px;
    height: 95%;
    overflow: auto;
  }
  &-message {
    margin: 16px 0 5px;
    line-height: 20px;
    font-size: 16px;
    color: #ffffff;
  }
  &-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
  }
  &-result {
    position: relative;
    padding: 53px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #ffffff;
    &-correct {
      font-weight: bold;
      font-size: 64px;
      line-height: 64px;
    }
    &-questions {
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
    }
    .CircularProgressbar {
      max-width: 150px;
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-share {
      margin-bottom: 10px;
      padding: 20px 20px 10px;
      position: absolute;
      bottom: 0;
      right: 10px;
    }
  }
  &-close {
    padding: 15px 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    background: #8fcc48;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 12px 12px;
    cursor: pointer;
    &-inner {
      display: block;
      margin: 0 auto;
      padding: 8px;
      width: 75%;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      background: #004e47;
      border-radius: 25px;
    }
  }
  &-list {
    margin-bottom: 80px;
    padding: 0;
    list-style-type: none;
    &-item {
      height: 56px;
      overflow: hidden;
      margin-bottom: 5px;
      padding: 0 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #009688;
      border-radius: 4px;
      @media (min-width: 768px) {
        height: 100px;
      }
      &-image {
        max-width: 100px;
        width: 20%;
        border-radius: 4px 0 0 4px;
      }
      &-name {
        text-align: left;
        padding-left: 15px;
        width: 100%;
        margin-right: auto;
        font-size: 4.5vw;
        font-weight: bold;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        @media (min-width: 768px) {
          font-size: 22px;
        }
      }
      &-link {
        height: 24px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 12px;
        font-size: 3.8vw;
        font-weight: bold;
        text-decoration: none;
        color: #ffffff;
        background: #004e47;
        border-radius: 4px;
        cursor: pointer;
        @media (min-width: 768px) {
          padding: 10px 20px;
          font-size: 22px;
        }
        &.vr {
          margin-left: 5px;
          background: #0d18ab;
        }
      }
    }
  }
}
