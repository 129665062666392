.Selector {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #3cb0d5;
  border-radius: 30px 30px 0px 0px;
  transform: translate(0, 130px);
  transition: all 0.3s ease;
  z-index: 100;
  &.is-display {
    transform: translate(0, 0);
  }
  &.is-stop {
    display: none;
  }
  &.is-boys {
    background: #39dbe5;
    .Selector-handle-inner {
      background: #19a9bd;
    }
  }
  &-handle {
    padding: 11px 0;
    &-inner {
      margin: 0 auto;
      display: block;
      width: 65%;
      height: 5px;
      background: #2388a7;
      border-radius: 2px;
    }
  }
  &-list {
    margin: 0 0 10px 0;
    padding: 5px 15%;
    text-align: left;
    list-style-type: none;
    &-item {
      position: relative;
      margin: 6px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-name {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
      }
      &-button {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 0;
        z-index: 1;
      }
    }
  }
}
