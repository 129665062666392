.Hint {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 60px;
  z-index: 2;
  &-content {
    margin: 1rem 0;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: white;
    cursor: pointer;
  }
}
