* {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic,
    "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
}
html,
body {
  background: linear-gradient(0deg, #ff6d6d 0.3%, #ffd02b 99.72%);
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
.App {
  position: relative;
  text-align: center;
  background: linear-gradient(179.66deg, #ff6d6d 0.3%, #ffd02b 99.72%);
  overflow: hidden;
  transition: all 0.4s ease;
  &.is-boys {
    background: linear-gradient(179.66deg, #2e36ff 0.3%, #2ce7ba 99.72%);
  }
  &-content {
    &.is-finished {
      height: 100%;
      filter: blur(3px);
    }
    &.is-open-score {
      filter: blur(3px);
      transform: scale(0.8);
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "./sass/Cover.scss";
@import "./sass/Background.scss";
@import "./sass/Header.scss";
@import "./sass/Progress.scss";
@import "./sass/Girl.scss";
@import "./sass/Selector.scss";
@import "./sass/Result.scss";
@import "./sass/Pagenation.scss";
@import "./sass/Score.scss";
@import "./sass/GirlVideo.scss";
@import "./sass/Hint.scss";
@import "./sass/Share.scss";
@import "./sass/SelectType.scss";
@import "./sass/animations/Heart.scss";
