.GirlVideo {
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0%, 100%);
  opacity: 0;
  transition: all 0.4s ease;
  &.is-display {
    opacity: 1;
    transform: translate(0%, 0%);
  }
  &-inner {
    position: absolute;
    width: 100vw;
    max-width: 476px;
    height: 84vw;
    max-height: 306px;
    top: 50%;
    left: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    background: black;
  }
  &-iframe {
    width: 100%;
    max-width: 476px;
    height: 84vw;
    max-height: 306px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
