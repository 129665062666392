.Pagenation {
  padding: 12px 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &-image {
    margin-bottom: 5px;
    width: 34px;
    height: 34px;
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.165em;
    color: #ffffff;
  }
}
