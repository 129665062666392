.SelectType {
  &-title {
    margin: 15px 0 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
  }
  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-key {
    font-size: 16px;
    color: #ffffff;
    z-index: 1;
    cursor: pointer;
    &.is-selected {
      font-weight: bold;
    }
  }
  &-selector {
    position: relative;
    margin: 0 10px;
    width: 70px;
    &:before {
      margin: 0 auto;
      display: block;
      content: "";
      width: 60px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      background: #efefef;
      transform: translate(0%, -50%);
      border-radius: 4px;
    }
    &-button {
      display: block;
      width: 20px;
      height: 20px;
      background: #e91e63;
      border-radius: 50px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      transform: translate(0, 0);
      transition: all 0.2s ease;
      &.is-boys {
        background: #173afb;
        transform: translate(50px, 0);
      }
    }
  }
}
