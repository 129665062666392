.Share {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0%, -130%);
  transition: all 0.4s ease;
  z-index: 1000;
  &.is-display {
    transform: translate(0, 0);
  }
  &-container {
    padding: 20px 0;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &-button {
    padding: 5px 15px;
    font-size: 12px;
    color: white;
    background: #1da1f2;
    border: 0;
    border-radius: 20px;
    outline: none;
  }
}
