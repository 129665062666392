.Header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-title {
    margin: 10px 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: white;
  }
}
